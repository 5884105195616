@font-face {
  font-family: "LatoRegular";
  src: local("LatoRegular"),
    url("./assets/fonts/Lato-Regular.ttf") format("truetype");
}

.App {
  font-family: "LatoRegular";
  background-color: #a1c5df;
  padding-top: 1em;
}
